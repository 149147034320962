<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <div class="divFrom">
        <div class="up_div">
          <div>
            <a-upload :before-upload="beforeUpload" class="upload-list-inline" v-model:file-list="fileList" accept=".xlsx">
              <a-button>
                选择文件
              </a-button>
            </a-upload>
          </div>
          <div>
            <a-button type="primary" :disabled="fileList.length === 0" :loading="uploading" @click="handleUpload">
              <upload-outlined></upload-outlined>
              {{ uploading ? 'Uploading' : '上传文件' }}
            </a-button>
          </div>

        </div>
      </div>
    </template>
    <template #toolbarRight>

    </template>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <span v-if="record.status!=null&&record.status===0">
          <a @click="offerstart(record)">开始计算</a>
        </span>
        <span v-if="record.status!=null&&record.status===2">
          <a @click="offerDatail(record)">报价明细</a>
        </span>
      </template>
      <template #status="{ record }">
        <p v-if="record.status===0">未开始计算</p>
        <p v-if="record.status===1">进行中</p>
        <p v-if="record.status===2">已完成</p>
      </template>
    </a-table>
  </suy-table>
  <a-drawer title="报价明细" width="80%" placement="right" :closable="true" v-model:visible="visible">
    <suy-table :reload="loadDataDatail" v-model:columns="columnsDatail" >
      <template #toolbarRight>
      </template>
      <a-table :columns="columnsDatail" :row-key="record => record.id" :data-source="loadDataDatail" :pagination="paginationDatail" :loading="loadingDetail" @change="handleTableChangeDetail">
        <template #sort="{ index }">
          {{index+1}}
        </template>
      </a-table>
    </suy-table>
  </a-drawer>
</template>
<script>
import { toRefs, reactive, onMounted, ref } from 'vue'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
import { carUpload } from '@/api/transport/transport'
import { batchOfferPage, offerFile, batchOfferstart, batchOfferDatail } from '@/api/transport/offer'
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      visible: false,
      uploading: false,
      fileList: ref([]),
      loading: false,
      loadingDetail: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      paginationDatail: {
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '100', '500', '1000'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        name: ''
      },
      listData: [],
      loadDataDatail: [],
      columns: [
        {
          title: '上报人',
          dataIndex: 'offer',
          key: 'offer'
        },
        {
          title: '上报时间',
          dataIndex: 'offerTime',
          key: 'offerTime'
        },
        {
          title: '是否完成',
          dataIndex: 'status.label',
          slots: {
            customRender: 'status'
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          slots: {
            customRender: 'operation'
          }
        }
      ],
      columnsDatail: [
        {
          title: '起点',
          dataIndex: 'startCity',
          key: 'startCity'
        },
        {
          title: '终点',
          dataIndex: 'endCity',
          key: 'endCity'
        },
        {
          title: '成本',
          dataIndex: 'costPrice',
          key: 'costPrice'
        }, {
          title: '是否查询',
          dataIndex: 'status.label',
          key: 'status.label'
        }, {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark'
        }

      ]

    })
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await batchOfferPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.searchForm.name
      })
      state.listData = records

      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(loadData)
    const beforeUpload = file => {
      state.fileList.length = 0
      state.fileList = [...state.fileList, file]

      return false
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const handleTableChangeDetail = (pag, filters, sorter) => {
      state.loadingDetail = true

      setTimeout(function () {
        state.loadingDetail = false
        state.pagination.current = pag.current
        state.pagination.pageSize = pag.pageSize
      }, 1000)
    }
    const query = () => {
      state.pagination.current = 1
      loadData()
    }
    const handleUpload = () => {
      state.uploading = true
      const formData = new FormData()
      state.fileList.forEach(file => {
        formData.append('file', file.originFileObj)
      })
      carUpload(formData).then((res) => {
        if (res.code === 10000) {
          state.fileList = []
          offerFile(res.data.id, '').then((res) => {
            message.info(res.msg)
            state.uploading = false
            loadData()
          })
        } else {
          message.error(res.msg)
        }
        state.uploading = false
      })
    }

    const offerstart = record => {
      console.log('record', {})
      batchOfferstart(record.id, {}).then((res) => {
        if (res.code === 10000) {
          message.info(res.msg)
          loadData()
        }
      })
    }
    const offerDatail = record => {
      state.visible = true
      state.loadingDetail = true
      batchOfferDatail(record.id, {}).then((res) => {
        if (res.code === 10000) {
          state.loadDataDatail = res.data
          state.paginationDatail.pageSizeOptions = ['10', '100', '500', '1000', res.data.length]
          state.loadingDetail = false
        }
      })
    }
    return {
      ...toRefs(state),
      offerDatail,
      loadData,
      handleTableChange,
      handleTableChangeDetail,
      offerstart,
      beforeUpload,
      handleUpload,
      query
    }
  }
}
</script>
